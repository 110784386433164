@import '../../styles/mixins';

$paper-color: #f1ede9;
$paper-line: #94acd4;

.notepad {
  width: 100%;
  box-shadow: 10px 10px 40px rgba(black, 0.15);
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  margin-bottom: 2rem;
  @include md {
    width: fit-content;
  }
}

.top {
  width: 100%;
  height: 30px;
  background: #333;
  border-radius: 5px 5px 0 0;
}

.date {
  background: $paper-color;
  text-align: center;
  font-size: 22px;
  color: #8b0000;
  line-height: 32px;
  font-family: 'Shadows Into Light', cursive;
  padding: 5px 0;
  // border: 1px solid black;
  // border-bottom-color: $paper-line;
}

.paper {
  width: 100%;
  height: 100%;
  min-height: 20vh;
  padding: 0 20px;
  background: repeating-linear-gradient(
    $paper-color,
    $paper-color 31px,
    $paper-line 31px,
    $paper-line 32px
  );
  font-family: 'Shadows Into Light', cursive;
  line-height: 32px;
  outline: 0;
  border: 0;
  font-size: 22px;
  @include md {
    min-width: 600px;
    max-width: 600px;
  }
}
