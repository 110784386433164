*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
}
