@import '../../styles/global';
@import '../../styles/mixins';

.container {
  max-width: 128rem;
  margin: 0 auto;
  width: 100%;
  @include md {
    padding: 4rem;
  }
}
.header {
  padding: 1rem;
}
